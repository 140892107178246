<template>
  <NuxtLink
    :to="data.href"
    :target="isOutgoing ? '_blank' : '_self'"
    class="inline-block rounded-full px-5 py-2.5 text-sm font-medium transition duration-300 ease-in-out focus:outline-none focus:ring-4 focus:ring-yellow-300"
    :class="btnTheme">
    <div class="inline-flex items-center gap-2 text-center" @click="handleCheckupModal">
      {{ data.label }}
      <SvgoArrowRight class="text-lg" />
    </div>
  </NuxtLink>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { useNavigationModalStore } from "~/store/navigationModal";
import { useVorsorgeModalStore } from "~/store/vorsorgeModal";

const props = defineProps<{
  data: any;
}>();
const isOutgoing = computed(() => {
  return typeof props.data.href === "string" && props.data.href.startsWith("http");
});

const btnTheme = computed(() => {
  switch (props.data.theme) {
    case "nav-primary":
      return "bg-white hover:bg-yellow-200 ";
    case "muted":
      return "bg-transparent hover:underline";
    case "secondary":
      return "border border-gray-900 bg-white hover:bg-gray-100";
    case "primary":
    default:
      return "bg-yellow hover:bg-yellow-300";
  }
});

const { toggleModal: toggleNavigationModal } = useNavigationModalStore();
const { toggleModal: toggleVorsorgeModal } = useVorsorgeModalStore();

function handleCheckupModal(e: Event) {
  if (props.data.href.endsWith("vorsorgemedizin#info")) {
    e.preventDefault();
    toggleVorsorgeModal();
  } else if (props.data.href.endsWith("vorsorgemedizin#uniqa-vorsorge")) {
    e.preventDefault();
    toggleNavigationModal();
  }
}
</script>

<style scoped></style>
